// HomePage.jsx
import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/qr-reader');
  };

  const handleButtonClick_map = () => {
    navigate('/map');
  }

  const handleButtonClick_Add_data = () => {
    navigate('/add_data');
  }

  const handleButtonClick_Delete = () => {
    navigate('/delete');
  }

  const handleButtonClick_MapControl = () => {
    navigate('/map_control');
  }

  return (
    <Container maxWidth={false}>
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '2rem',
          backgroundSize: 'cover',
        }}
      >
        <Typography variant="h2" align="center" gutterBottom>
          Welcome
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleButtonClick}
          sx={{ marginTop: '2rem' }}
        >
          QRリーダー
        </Button>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleButtonClick_Add_data}
          sx={{ marginTop: '2rem' }}
        >
          データ追加
        </Button>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleButtonClick_Delete}
          sx={{ marginTop: '2rem' }}
        >
          データ削除
        </Button>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleButtonClick_map}
          sx={{ marginTop: '2rem' }}
        >
          全房マップ
        </Button>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleButtonClick_MapControl}
          sx={{ marginTop: '2rem' }}
        >
          マップコントロール
        </Button>

      </Box>
    </Container>
  );
};

export default HomePage;
