import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Alert,
  Grid,
  Autocomplete,
  Box,
  InputAdornment,
} from '@mui/material';

import { useNavigate, useLocation } from 'react-router-dom';
import Map_add from './Map_add';

const AddData = ({ data }) => {
  const routeLocation = useLocation(); // Rename 'location' to 'routeLocation' to avoid naming conflict

  const initialCropId = routeLocation.state?.cropId || 0;
  const [newData, setNewData] = useState({
    crop_id: initialCropId,
    location: '',
    worker: '',
    grain_count: 0,
    field_name: '',
    images: null,
    cultivation_status: [],
  });

  const [cultivationName, setCultivationName] = useState('');
  const [cultivationStatusValue, setCultivationStatusValue] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [statusOptions, setStatusOptions] = useState([]); // 追加
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    const resizeWidth = 600; // リサイズ後の幅
    const resizeHeight = 800; // リサイズ後の高さ
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = resizeWidth;
        canvas.height = resizeHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, resizeWidth, resizeHeight);
        canvas.toBlob((blob) => {
          // リサイズした画像の blob を新しい FormData にセット
          const resizedImage = new File([blob], file.name, { type: 'image/jpeg' });
          setNewData({ ...newData, images: resizedImage });
        }, 'image/jpeg');
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  const [isAvailable, setAvailable] = useState(false);
  const [position, setPosition] = useState({ latitude: null, longitude: null, accuracy: null });
  // useEffectが実行されているかどうかを判定するために用意しています
  const isFirstRef = useRef(true);
  const accuracyThreshold = 40;
  const [bestAccuracy, setBestAccuracy] = useState(Infinity);


  // const gps_options = { enableHighAccuracy: true, maximumAge: 0};
  const gps_options = useRef({ enableHighAccuracy: true, maximumAge: 0, timeout: 1000 });

  const [tracking, setTracking] = useState(false);

  const toggleTracking = () => {
    if (tracking) {
      setBestAccuracy(Infinity);
    }
    setTracking((prevTracking) => !prevTracking);
  };

  useEffect(() => {
    let warmupGeoId = null;

    // GPSセンサーのウォームアップを行う関数
    const gpsWarmup = () => {
      if ('geolocation' in navigator) {
        warmupGeoId = navigator.geolocation.watchPosition(
          () => { }, // 位置情報が更新された時のコールバック（何もしない）
          () => { }, // エラー時のコールバック（何もしない）
          { enableHighAccuracy: true, maximumAge: 0, timeout: 1000 }
        );
      }
    };

    // ウォームアップを開始
    gpsWarmup();

    // コンポーネントがアンマウントされる時にウォームアップを停止
    return () => {
      if (warmupGeoId) navigator.geolocation.clearWatch(warmupGeoId);
    };
  }, []);


  useEffect(() => {
    // isFirstRef.current = false;
    let geoId = null;

    const startGeoWatch = () => {
      // if ('geolocation' in navigator) {
      //   setAvailable(true);
      if (tracking && 'geolocation' in navigator) {
        setAvailable(true);
        if (geoId) {
          navigator.geolocation.clearWatch(geoId); // 前のトラッキングをクリアする
        }
        geoId = navigator.geolocation.watchPosition(
          (position) => {
            if (position.coords.accuracy < bestAccuracy) {
              // accuracyがしきい値未満の場合のみ、位置情報とaccuracyを更新
              setPosition({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                accuracy: position.coords.accuracy
              });
              setBestAccuracy(position.coords.accuracy);
            }
            // accuracyがしきい値以上の場合はaccuracyのみ更新
            else {
              setPosition((prevPosition) => ({
                ...prevPosition,
                accuracy: position.coords.accuracy
              }));
            }
          },
          (e) => {
            console.log(e.message);
          },
          gps_options ?? { enableHighAccuracy: true, maximumAge: 0, timeout: 1000 }
        );
        // setWatchId(geoId);

      }
    };

    if (tracking) { // トラッキングの状態が変更された時のみ位置情報の取得処理を開始
      startGeoWatch();
    }
    else { // トラッキングが無効になった場合は位置情報の監視を停止
      if (geoId) {
        navigator.geolocation.clearWatch(geoId);
      }
    }

    return () => {
      if (geoId) navigator.geolocation.clearWatch(geoId);
    };

  }, [tracking, bestAccuracy]);

  useEffect(() => {
    // position.latitude と position.longitude が両方とも null でない場合のみ更新
    if (position.latitude !== null && position.longitude !== null) {
      setNewData(prevData => ({
        ...prevData,
        location: `${position.latitude},${position.longitude}`
      }));
    }
  }, [position.latitude, position.longitude]); // positionのlatitudeとlongitudeを依存関係に追加


  useEffect(() => {
    // APIからデータを取得する関数
    const fetchCultivationMethods = async () => {
      try {
        const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_NAME}/api/v1/cultivation_methods/`);
        if (response.ok) {
          const data = await response.json();
          const names = data.map((method) => method.name);
          setStatusOptions(names); // 取得したデータから'name'フィールドの値をstatusOptionsにセット
        } else {
          console.error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // マウント時に一度だけデータを取得する
    fetchCultivationMethods();
  }, []); // 空の配列を渡すことで、最初のレンダー時にのみ実行されます

  const handleAddStatus = () => {
    if (cultivationName.trim() === '') {
      alert('Please enter a name for the status.');
      return;
    }

    setNewData((prevData) => ({
      ...prevData,
      cultivation_status: [
        ...prevData.cultivation_status,
        {
          id: Date.now(),
          name: cultivationName,
          value: cultivationStatusValue,
        },
      ],
    }));

    setCultivationName('');
    setCultivationStatusValue(false);
  };

  const handleAddData = () => {
    const formData = new FormData();

    formData.append('crop_id', newData.crop_id);
    formData.append('location', newData.location);
    formData.append('worker', newData.worker);
    formData.append('grain_count', newData.grain_count);
    formData.append('field_name', newData.field_name);
    formData.append('images', newData.images);
    formData.append('cultivation_status', JSON.stringify(newData.cultivation_status));



    if (formData.get('location')) {
      const [longitude, latitude] = formData.get('location').split(',').map(parseFloat);

      if (!isNaN(latitude) && !isNaN(longitude)) {
        formData.set('location', `POINT(${latitude} ${longitude})`);
      } else {
        console.error('Invalid latitude or longitude values');
      }
    }
    fetch(`https://${process.env.REACT_APP_DOMAIN_NAME}/api/v1/grapes/add_status/`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        setErrorMessage('');
        if (response.ok) {
          setSuccessMessage('データ追加完了');
          setTimeout(() => {
            setSuccessMessage('');
          }, 5000);
        } else {
          console.error('Add Data error:', response.statusText);
          return response.json().then((data) => {
            setErrorMessage(data.error);
          });
        }
      })
      .catch((error) => {
        console.error('Add Data error:', error);
      });
    formData.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });
  };

  const handleCheckboxChange = (statusId, isChecked) => {
    setNewData((prevData) => ({
      ...prevData,
      cultivation_status: prevData.cultivation_status.map((status) =>
        status.id === statusId ? { ...status, value: isChecked } : status
      ),
    }));
  };

  console.log(position.accuracy);

  return (
    <div>
      <Card>
        <CardContent>
          <Typography variant="h6">Add Data</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div>
                {!isFirstRef && !isAvailable}
                {isAvailable && (
                  <div>
                    <div>
                      {position.latitude !== null && position.longitude !== null ? (
                        <Map_add latitude={position.latitude} longitude={position.longitude} />
                      ) : null}
                    </div>
                  </div>
                )}
                <br />
                <Button variant="contained" color="secondary" onClick={toggleTracking}>
                  {tracking ? '位置情報の取得を停止' : '位置情報を取得'}
                </Button>
              </div>
              <br />
              <TextField
                label="Crop ID"
                fullWidth
                value={newData.crop_id}
                onChange={(e) => setNewData({ ...newData, crop_id: e.target.value })}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                label="Location"
                fullWidth
                value={`${position.latitude},${position.longitude}`}
                onChange={(e) => setNewData({ ...newData, location: e.target.value })}
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                label="Worker"
                fullWidth
                value={newData.worker}
                onChange={(e) => setNewData({ ...newData, worker: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Grain Count"
                fullWidth
                value={newData.grain_count}
                onChange={(e) => setNewData({ ...newData, grain_count: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Field Name"
                fullWidth
                value={newData.field_name}
                onChange={(e) => setNewData({ ...newData, field_name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                freeSolo
                options={statusOptions}
                value={cultivationName}
                onChange={(event, newValue) => {
                  // 選択されたオプションまたは入力された値を設定
                  setCultivationName(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                  // 入力フィールドの値が変更されたときに呼び出される
                  setCultivationName(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cultivation Name"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    label="Status"
                    checked={cultivationStatusValue}
                    onChange={(e) => setCultivationStatusValue(e.target.checked)}
                    color="primary"
                  />
                }
                label="Status"
              />
            </Grid>
          </Grid>
          <Button variant="contained" color="secondary" onClick={handleAddStatus}>
            作業項目追加
          </Button>
          <Grid item xs={12}>
            <TextField
              // label="Images"
              fullWidth
              type="file"
              onChange={handleImageChange}
            // onChange={(e) => setNewData({ ...newData, images: e.target.files[0] })}
            />
          </Grid>
          {newData.cultivation_status.map((status) => (
            <Grid item xs={12} key={status.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={status.value}
                    onChange={(e) => handleCheckboxChange(status.id, e.target.checked)}
                    color="primary"
                  />
                }
                label={status.name}
              />
            </Grid>
          ))}
          <Button variant="contained" color="secondary" onClick={handleAddData}>
            データ追加
          </Button>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          {successMessage && <Alert severity="success">{successMessage}</Alert>}
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => {
              navigate('/');
            }}
            sx={{ marginTop: '1rem' }}
          >
            Back
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default AddData;