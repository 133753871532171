// App.js
import { Container, CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
// import EditData from './components/EditData'; // Import the new component
import HomePage from './components/HomePage';
import QrCodeReader from './components/QrCodeReader';
import Map_total from './components/Map_total';
import Add_data from './components/Add_data';
import theme from './theme';
import Delete from './components/Delete';
import MapControl from './components/MapControl';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth={false} sx={{ paddingTop: '2rem' }}>
        <div className="App">
          <Router>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/qr-reader" element={<QrCodeReader />} />
              <Route path="/map" element={<Map_total />} />
              <Route path="/add_data" element={<Add_data />} />
              <Route path="/delete" element={<Delete />} />
              <Route path="/map_control" element={<MapControl />} />
            </Routes>
          </Router>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default App;
