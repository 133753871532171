import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // ルーティングライブラリからimportする
import MapDisplay from './MapDisplay_total';
// import MapDisplay from './MapDisplay_total_heat';


const ApiComponent = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // useNavigate フックを使ってルーティング関数を取得

  useEffect(() => {
    console.log(`https://${process.env.REACT_APP_DOMAIN_NAME}/api/v1/grapes/`);
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://${process.env.REACT_APP_DOMAIN_NAME}/api/v1/grapes/`);
        setData(response.data);
      } catch (err) {
        setError(err);
      }
    };


    fetchData();
  }, []);

  if (error) {
    return (
      <div>
        <button onClick={() => navigate('/')}>Back</button>
        <div>Error: {error.message}</div>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div>
        <button onClick={() => navigate('/')}>Back</button>
        <div>No data...</div>
      </div>
    );
  }

  const locations = data.map((item) => item.location);
  const cropids = data.map((item) => item.crop_id);
  const cultivationStatuses = data.map((item) => item.cultivation_status);
  const images = data.map((item) => item.images);
  const worker = data.map((item) => item.worker);

  return (
    <div>
      {/* {data.map((item, index) => (
        <div key={item.id}>
          <h1>Crop ID: {item.crop_id}</h1>
          <p>Location: {item.location}</p>
          <h2>Cultivation Status:</h2>
          <ul>
            {cultivationStatuses[index].map((status) => (
              <li key={status.id}>
                <p>Cultivation Method: {status.cultivation_method_name}</p>
                <p>Status: {status.status ? 'True' : 'False'}</p>
              </li>
            ))}
          </ul>
        </div>
      ))} */}
      {/* MapDisplayコンポーネントにlocationsとcultivation_statusesを渡す */}
      <MapDisplay locations={locations} cropids={cropids} cultivationStatuses={cultivationStatuses} worker={worker} images={images} />

      <Button
        variant="outlined"
        color="primary"
        fullWidth
        onClick={() => {
          navigate('/');
        }}
        sx={{ marginTop: '1rem' }}
      >
        Back
      </Button>
    </div>
  );
};

export default ApiComponent;