import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import iconMarker from 'leaflet/dist/images/marker-icon.png';
import iconBlue from '../icon_images/icon_blue.png';

const icon = L.icon({
  // iconRetinaUrl: iconRetina,
  iconUrl: iconBlue,
  iconSize: [36, 36], // アイコンのサイズを更新
  iconAnchor: [18, 36], // ピンの先が指定した座標を指すようにアンカーポイントを変更
  popupAnchor: [0, -18], // ポップアップがアイコンの上部に表示されるようにアンカーポイントを変更
});


const MapDisplay = ({ latitude,longitude }) => {
  return (
    <div>
      <p>緯度: {latitude}</p>
      <p>経度: {longitude}</p>
      <MapContainer
        center={[latitude, longitude]}
        zoom={20} // より大きな値に変更
        style={{ width: '100%', height: '400px' }}
      >
        <TileLayer
          // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          minZoom={13}
          maxZoom={25}
          url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={[latitude, longitude]} icon={icon}>
          <Popup>
            Location: {latitude}, {longitude}
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default MapDisplay;