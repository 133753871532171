import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import { useNavigate } from 'react-router-dom';
import { Button, Select, MenuItem } from '@mui/material';
import iconBlue from '../icon_images/icon_blue.png'; // Importing the custom blue icon
import iconRed from '../icon_images/icon_red.png'; // Importing the custom red icon

// Custom blue icon for the marker using Leaflet's built-in icon method
const blueIcon = L.icon({
    iconUrl: iconBlue,
    iconSize: [36, 36],
    iconAnchor: [18, 36],
    popupAnchor: [0, -18],
});

// Custom red icon for the marker using Leaflet's built-in icon method
const redIcon = L.icon({
    iconUrl: iconRed,
    iconSize: [36, 36],
    iconAnchor: [18, 36],
    popupAnchor: [0, -18],
});

const LocationMarker = () => {
    const [position, setPosition] = useState(null);
    const map = useMapEvents({
        click(e) {
            setPosition(e.latlng);
            map.flyTo(e.latlng, map.getZoom());
            console.log('New position:', e.latlng); // Logging the new position on map click
        }
    });

    const sendCoordinates = (position) => {
        axios.post('http://localhost:8000/publish-gps/', {
            latitude: position.lat,
            longitude: position.lng
        })
            .then(response => console.log('Success:', response))
            .catch(error => console.error('Error:', error));
    };

    return (
        <div>
            {position && (
                <button onClick={() => sendCoordinates(position)}>Send Coordinates</button>
            )}
            {position && (
                <Marker position={position} icon={blueIcon}></Marker>
            )}
        </div>
    );
};

const MapControl = () => {
    const navigate = useNavigate();
    const [currentLocation, setCurrentLocation] = useState(null);
    const [grapes, setGrapes] = useState([]);
    const [selectedGrape, setSelectedGrape] = useState("");
    const [selectedPosition, setSelectedPosition] = useState(null);
    const mapRef = useRef(null);

    useEffect(() => {
        const watchId = navigator.geolocation.watchPosition(
            function (position) {
                const newLocation = [position.coords.latitude, position.coords.longitude];
                setCurrentLocation(newLocation);
                console.log('Current location:', newLocation);
                if (mapRef.current) {
                    mapRef.current.setView(newLocation, mapRef.current.getZoom());
                }
            },
            function (error) {
                console.error("Error Code = " + error.code + " - " + error.message);
            },
            { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
        );

        axios.get(`https://${process.env.REACT_APP_DOMAIN_NAME}/api/v1/grapes/`)
            .then(response => {
                console.log(response.data);
                setGrapes(response.data.map(grape => ({
                    id: grape.id,
                    cropId: grape.crop_id,
                    field: grape.field,
                    fieldName: grape.field_name,
                    location: grape.location,
                    worker: grape.worker,
                    grainCount: grape.grain_count,
                    createdAt: grape.created_at,
                    updatedAt: grape.updated_at,
                    cultivationStatus: grape.cultivation_status.map(status => ({
                        id: status.id,
                        method: status.cultivation_method_name,
                        status: status.status
                    })),
                    images: grape.images
                })));
            })
            .catch(error => console.error('Failed to fetch grapes:', error));
    }, []);

    const handleGrapeChange = (event) => {
        const selectedId = event.target.value;
        console.log("selectedId", selectedId);
        setSelectedGrape(selectedId);
        const selectedGrape = grapes.find(grape => grape.id === selectedId);
        console.log("selectedGrape", selectedGrape);
        if (selectedGrape && selectedGrape.location) {
            const locationString = selectedGrape.location;
            const coordinates = locationString.match(/POINT \(([^)]+)\)/)[1].split(' ');
            const newPosition = { lat: parseFloat(coordinates[1]), lng: parseFloat(coordinates[0]) };
            setSelectedPosition(newPosition);
            if (mapRef.current) {
                mapRef.current.flyTo(newPosition, mapRef.current.getZoom());
            }
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '5px' }}>
            {currentLocation && (
                <MapContainer center={currentLocation} zoom={15} style={{ height: '400px', width: '100%', marginBottom: '5px' }} whenCreated={mapInstance => mapRef.current = mapInstance}>
                    <TileLayer
                        minZoom={10}
                        maxZoom={25}
                        url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker position={currentLocation} icon={blueIcon} />
                    {selectedPosition && <Marker position={selectedPosition} icon={redIcon} key={selectedPosition.lat + selectedPosition.lng} />}
                    <LocationMarker />
                </MapContainer>
            )}
            <Select
                value={selectedGrape}
                onChange={handleGrapeChange}
                displayEmpty
                fullWidth
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{ width: '100%', marginBottom: '5px' }}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {grapes.map((grape) => (
                    <MenuItem key={grape.id} value={grape.id}>
                        {`Crop ID: ${grape.cropId}, Field: ${grape.fieldName}, Worker: ${grape.worker}, Location: ${grape.location}`}
                    </MenuItem>
                ))}
            </Select>
            <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => {
                    const message = {
                        longitude: currentLocation[1],
                        latitude: currentLocation[0]
                    };
                    console.log(`Sending message: ${JSON.stringify(message)}`);
                    axios.post('https://db.smgrobo.jp/publish-gps/', message)
                        .then(response => {
                            if (response.status === 200) {
                                console.log("Message published successfully:", response.data);
                            } else {
                                console.log("Failed to publish message. Status code:", response.status, "Response:", response.statusText);
                            }
                        })
                        .catch(error => console.error('Error:', error));
                }}
            >
                Send Current Location
            </Button>
            <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => {
                    navigate('/');
                }}
            >
                Back
            </Button>
        </div>
    );
};

export default MapControl;

