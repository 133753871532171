import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog, // ダイアログコンポーネントを追加
  DialogTitle,
  DialogContent
} from '@mui/material';

const GrapeStatus = ({ cropid }) => {
  const [data, setData] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [open, setOpen] = useState(false); // ダイアログの開閉状態
  const isMobile = window.innerWidth <= 500;

  useEffect(() => {
    const apiUrl = 'https://1ff6-133-23-11-43.ngrok-free.app/api/v1/grape_history/';

    fetch(apiUrl)
      .then((response) => response.json())
      .then((responseData) => {
        setData(responseData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  const filteredData = cropid ? data.filter((item) => item.crop_id === cropid) : data;

  filteredData.sort((a, b) => b.id - a.id);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setOpen(true); // ダイアログを開く
  };

  const handleClose = () => {
    setOpen(false); // ダイアログを閉じる
  };

  return (
    <div>
      {filteredData.map((item) => (
        <div key={item.id} style={{ marginBottom: '10px' }}>
          <img
            src={item.images}
            style={{ width: isMobile ? '100%' : '400px', height: isMobile ? '100%' : 'auto', cursor: 'pointer' }}
            alt={`Image for Crop ID: ${item.crop_id}`}
            onClick={() => handleItemClick(item)}
          />
        </div>
      ))}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Information</DialogTitle>
        <DialogContent>
          {selectedItem && (
            <>
              <Typography>Location: {selectedItem.location}</Typography>
              <Typography>Worker: {selectedItem.worker}</Typography>
              <Typography>Grain Count: {selectedItem.grain_count}</Typography>
              <img
                src={selectedItem.images}
                alt={`Image for Crop ID: ${selectedItem.crop_id}`}
                style={{ width: '100%', height: '100%' }}
              />
              {renderCultivationStatus(selectedItem.cultivation_status)}
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

const renderCultivationStatus = (cultivationStatus) => {
  return cultivationStatus.map((status) => (
    <div key={status.id}>
      <Typography>{status.cultivation_method_name}</Typography>
      <Typography>{status.status ? 'True' : 'False'}</Typography>
      <Typography>Created At: {new Date(status.created_at).toLocaleString()}</Typography>
      <Typography>Updated At: {new Date(status.updated_at).toLocaleString()}</Typography>
    </div>
  ));
};

export default GrapeStatus;
