import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
} from '@mui/material';
import axios from 'axios';
import QrReader from 'react-qr-scanner';
import { useNavigate } from 'react-router-dom';
import DataDisplay from './DataDisplay_test';
import AddData from './Add_data';

const QrCodeReader = () => {
  const [result, setResult] = useState('');
  const [fetchedData, setFetchedData] = useState(null);
  const [worker, setWorker] = useState('');
  const [history, setHistory] = useState('');
  const [grainCount, setGrainCount] = useState('');
  const [cameraRunning, setCameraRunning] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [readed_cropid, setReadedCropid] = useState('');

  const navigate = useNavigate();


  // Fetch data from API
  const fetchData = async (url) => {
    try {
      const response = await axios.get(url);
      setFetchedData(response.data);
      setWorker(response.data.worker);
      setGrainCount(response.data.grain_count);
      setHistory(response.data.history);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        setNotFound(true);
      }
    }
  };

  useEffect(() => {
    if (result) {
      fetchData(result);
    }
  }, [result]);

  const handleScan = (data) => {
    if (data) {
      setResult(data.text);
      const match = data.text.match(/grapes\/(\d+)\//);
      const cropId = match ? match[1] : "";
      setReadedCropid(cropId);
      console.log(data.text, "crop_id:", cropId);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  // const switchToExternalCamera = async () => {
  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
  //     // ストリームをQrReaderコンポーネントに渡す
  //     // 注意: カメラを切り替える前にカメラストリームを停止する必要があることに注意してください
  //     setCameraRunning(false);
  //   } catch (error) {
  //     console.error('Error switching to external camera:', error);
  //   }
  // };

  return (
    <Container maxWidth={false}>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100vh',
          py: 3,
          position: 'relative',
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          QR Code Reader
        </Typography>
        {cameraRunning && !result && (
          <>
            <QrReader
              delay={500}
              onError={handleError}
              onScan={handleScan}
              style={{ width: '100%' }}
              constraints={{
                video: { facingMode: 'environment' }
              }
              }
            />
            {/* <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={switchToExternalCamera}
            sx={{ marginTop: '1rem' }}
          >
            Switch to External Camera
          </Button> */}
          </>
        )}

        {result && (
          <Typography variant="body1" align="center" gutterBottom>
            Result: {result}
          </Typography>
        )}

        {fetchedData && (
          <div>
            <DataDisplay data={fetchedData} result={result} />
          </div>
        )}

        {notFound && (
          <div>
            <Typography variant="body1" align="center" gutterBottom>
              Data not found
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() => navigate('/add_data', { state: { cropId: readed_cropid } })}
            >
              Add Data
            </Button>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() => {
                setResult('');
                setNotFound(false);
              }}
              sx={{ marginTop: '1rem' }}
            >
              Rescan
            </Button>
          </div>
        )}

        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => {
            setCameraRunning(false);
            navigate('/');
          }}
          sx={{ marginTop: '1rem' }}
        >
          Back
        </Button>
      </Box>
    </Container>
  );
};

export default QrCodeReader;
