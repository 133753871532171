// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    font-family: sans-serif;
    text-align: left;
  }
  
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  #root {
    height: 100vh;
  }
  
  /* sizing of MapContainer */
  .leaflet-container {
    height: 100vh;
  }
  
  .cluster-icon {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    background-color: rgb(197, 216, 94);
    transform: translate(-25%, -25%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.3rem;
  }
  
    /* テキストのサイズを大きくする */
  .smallText {
    font-size: 20px; /* 12pxから16pxに変更 */
  }

  /* ボタンのサイズを大きくする例（.button-classは仮のセレクタ） */
  .button-class {
    font-size: 20px; /* ボタンのテキストサイズを大きくする */
    padding: 10px 20px; /* ボタンの内側のスペースを増やす */
    width: auto; /* 幅を自動調整にする（必要に応じて） */
    height: auto; /* 高さを自動調整にする（必要に応じて） */
  }
`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;IACtB,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,aAAa;EACf;;EAEA,2BAA2B;EAC3B;IACE,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,mCAAmC;IACnC,gCAAgC;IAChC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;EACnB;;IAEE,mBAAmB;EACrB;IACE,eAAe,EAAE,kBAAkB;EACrC;;EAEA,yCAAyC;EACzC;IACE,eAAe,EAAE,sBAAsB;IACvC,kBAAkB,EAAE,oBAAoB;IACxC,WAAW,EAAE,sBAAsB;IACnC,YAAY,EAAE,uBAAuB;EACvC","sourcesContent":[".App {\n    font-family: sans-serif;\n    text-align: left;\n  }\n  \n  * {\n    box-sizing: border-box;\n    margin: 0;\n    padding: 0;\n  }\n  \n  #root {\n    height: 100vh;\n  }\n  \n  /* sizing of MapContainer */\n  .leaflet-container {\n    height: 100vh;\n  }\n  \n  .cluster-icon {\n    height: 3rem;\n    width: 3rem;\n    border-radius: 50%;\n    background-color: rgb(197, 216, 94);\n    transform: translate(-25%, -25%);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-weight: 700;\n    font-size: 1.3rem;\n  }\n  \n    /* テキストのサイズを大きくする */\n  .smallText {\n    font-size: 20px; /* 12pxから16pxに変更 */\n  }\n\n  /* ボタンのサイズを大きくする例（.button-classは仮のセレクタ） */\n  .button-class {\n    font-size: 20px; /* ボタンのテキストサイズを大きくする */\n    padding: 10px 20px; /* ボタンの内側のスペースを増やす */\n    width: auto; /* 幅を自動調整にする（必要に応じて） */\n    height: auto; /* 高さを自動調整にする（必要に応じて） */\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
