import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tabs,
  Tab,
  TextField,
  Button,
  Checkbox,
  Divider,
  Grid,
  Autocomplete,
  FormControlLabel,
} from '@mui/material';

import MapDisplay from './MapDisplay_test';
import History from './History';
import Add_data from './Add_data';
import { useNavigate } from 'react-router-dom';
import '../styles.css';
import { createTheme } from '@mui/material/styles';



const DataDisplay = ({ data, result }) => {
  const [editMode, setEditMode] = useState(false);
  const [selectedTab, setSelectedTab] = useState('data');

  // data ステート変数を useState で管理する
  const [dataState, setDataState] = useState({ ...data });

  // cultivation_status の各項目の状態を管理するステート変数
  const [cultivationStatusState, setCultivationStatusState] = useState({});

  const [imagePreview, setImagePreview] = useState(null);
  // const [currentImage, setCurrentImage] = useState(null);

  const [cultivationName, setCultivationName] = useState('');
  const [cultivationStatusValue, setCultivationStatusValue] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(null);
  const updatedDateTime = new Date().toISOString();

  useEffect(() => {
    // cultivationName が変更されたら、現在の日時を更新
    setCurrentDateTime(new Date().toISOString());
  }, [cultivationName]); // 依存配列に cultivationName を追加

  useEffect(() => {
    // コンポーネントがマウントされたときに既存の画像URLがある場合、それをFileオブジェクトに変換
    if (data.images) {
      fetch(data.images)
        .then(response => response.blob())
        .then(blob => {
          const file = new File([blob], "image.jpg", { type: "image/jpeg" });
          setDataState(prevDataState => ({ ...prevDataState, images: file }));
          setImagePreview(URL.createObjectURL(file));
        })
        .catch(error => console.error("Error fetching image:", error));
    }
  }, [data]);

  const handleAddStatus = () => {
    if (cultivationName.trim() === '') {
      alert('Please enter a name for the status.');
      return;
    }
  
    const newStatusId = Date.now(); // 一意のIDを生成
  
    // 新しい status を dataState に追加
    setDataState(prevDataState => ({
      ...prevDataState,
      cultivation_status: [
        ...prevDataState.cultivation_status,
        {
          id: newStatusId,
          cultivation_method_name: cultivationName,
          status: cultivationStatusValue,
          created_at: currentDateTime,
          updated_at: updatedDateTime,
        },
      ],
    }));
  
    // 新しい status を cultivationStatusState にも追加
    setCultivationStatusState(prevState => ({
      ...prevState,
      [newStatusId]: cultivationStatusValue,
    }));
  
    setCultivationName('');
    setCultivationStatusValue(false);
  };

  const isMobile = window.innerWidth <= 500;

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    const resizeWidth = 400; // リサイズ後の幅
    const resizeHeight = 600; // リサイズ後の高さ
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = resizeWidth;
        canvas.height = resizeHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, resizeWidth, resizeHeight);
        canvas.toBlob((blob) => {
          // リサイズした画像の blob を新しい FormData にセット
          const resizedImage = new File([blob], file.name, { type: 'image/jpeg' });
          setDataState({ ...dataState, images: resizedImage });
          setImagePreview(URL.createObjectURL(resizedImage));
          // setCurrentImage(resizedImage);
        }, 'image/jpeg');
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  // data プロパティが変更されたときに dataState を更新する
  useEffect(() => {
    setDataState({ ...data });
    // cultivation_status の状態も初期化
    const cultivationStatusInitialState = {};
    data.cultivation_status.forEach((status) => {
      cultivationStatusInitialState[status.id] = status.status;
    });
    setCultivationStatusState(cultivationStatusInitialState);
  }, [data]);

  console.log(dataState);

  useEffect(() => {
    const updatedData = {
      ...dataState,
      cultivation_status: dataState.cultivation_status.map((status) => ({
        ...status,
        status: cultivationStatusState[status.id],
      })),
    };
    setDataState(updatedData);
  }, [cultivationStatusState]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const saveData = () => {
    const url = result.endsWith('/') ? result : `${result}/`;
    const formData = new FormData();
  
    // dataState から画像以外のデータを追加
    Object.keys(dataState).forEach(key => {
      if (key !== 'images') {
        formData.append(key, typeof dataState[key] === 'object' ? JSON.stringify(dataState[key]) : dataState[key]);
      }
    });
  
    if (dataState.images) {
      formData.append('images', dataState.images);
    }

    for (let [key, value] of formData.entries()) {
      console.log(key, value); // フォームデータの内容を確認
    }
  
    fetch(url, {
      method: 'PATCH',
      body: formData, // FormData を使用
      // 'Content-Type' ヘッダーは指定しない（ブラウザが自動的に処理する）
    })
    .then((response) => {
      if (response.ok) {
        setEditMode(false);
      } else {
        console.error('Save error:', response.statusText);
      }
    })
    .catch((error) => {
      console.error('Save error:', error);
    });
  };
  

  const handleCheckboxChange = (statusId, isChecked) => {
    // チェックボックスの状態を更新し、cultivationStatusStateを更新する
    setCultivationStatusState(prevState => ({
      ...prevState,
      [statusId]: isChecked,
    }));
  
    // dataState の cultivation_status も更新する
    setDataState(prevDataState => ({
      ...prevDataState,
      cultivation_status: prevDataState.cultivation_status.map(status => {
        if (status.id === statusId) {
          return { ...status, status: isChecked, updated_at: updatedDateTime };
        }
        return status;
      }),
    }));
  };

  const renderCultivationStatus = (cultivationStatus) => {
    if (selectedTab === 'history') {
      return cultivationStatus.map((status) => (
        <div key={status.id}>
          {/* キー名を表示せずに値だけを表示する */}
          <Typography>{status.cultivation_method_name}</Typography>
          <Typography>{status.status ? 'True' : 'False'}</Typography>
          <Typography>Created At: {new Date(status.created_at).toLocaleString()}</Typography>
          <Typography>Updated At: {new Date(status.updated_at).toLocaleString()}</Typography>
          {status.history && status.history.length > 0 && (
            <TableContainer>
              {/* Table contents */}
            </TableContainer>
          )}
        </div>
      ));
    } else {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {cultivationStatus.map((status) => (
            <div key={status.id} style={{ marginRight: '20px', marginBottom: '20px' }}>
              {/* キー名を表示せずに値だけを表示する */}
              <Typography className="smallText">{status.cultivation_method_name}</Typography>
              <Typography className="smallText">{status.status ? 'True' : 'False'}</Typography>
              <Typography className="smallText">Created At: {new Date(status.created_at).toLocaleString()}</Typography>
              <Typography className="smallText">Updated At: {new Date(status.updated_at).toLocaleString()}</Typography>
            </div>
          ))}
        </div>
      );
    }
  };
  
  console.log(dataState.images);

  return (
    <div>
      <Card>
        <CardContent>
          <Typography variant="h6">Data Display</Typography>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab label="Data" value="data" />
            {/* <Tab label="History" value="history" /> */}
            <Tab label="Map Display" value="map" />
            <Tab label="History" value="all_history" />
          </Tabs>
          {selectedTab === 'map' ? (
            <MapDisplay location={dataState.location} />
          ) : selectedTab === 'history' ? (
            <div>
              {renderCultivationStatus(dataState.cultivation_status)}
            </div>
          ) : selectedTab === 'all_history' ? (
            <History cropid={dataState.crop_id} />
          ) :
          (
            <div>
              {editMode ? (
                <div>
                  <br />
                  <TextField
                    label="Worker"
                    value={dataState.worker}
                    onChange={(e) => setDataState({ ...dataState, worker: e.target.value })}
                    fullWidth
                  />
                  <br />
                  <br />
                  <TextField
                    label="Grain Count"
                    value={dataState.grain_count}
                    onChange={(e) => setDataState({ ...dataState, grain_count: e.target.value })}
                    fullWidth
                  />
                  <br />
                  <br />
                  <Grid item xs={12}>
                      <TextField
                        label="Cultivation Name"
                        fullWidth
                        value={cultivationName}
                        onChange={(e) => setCultivationName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            label="Status"
                            checked={cultivationStatusValue}
                            onChange={(e) => setCultivationStatusValue(e.target.checked)}
                            color="primary"
                          />
                        }
                        label="Status"
                      />
                    </Grid>
                  <Button variant="contained" color="primary" onClick={handleAddStatus} style={{ fontSize: '16px', padding: '10px 20px' }}>
                    作業項目追加
                  </Button>
                  <TextField
                    fullWidth
                    type="file"
                    onChange={handleImageChange}
                  />
                  <br />
                  {dataState.cultivation_status.map((status) => (
                    <div key={status.id}>
                      <Typography>
                        Cultivation Method: {status.cultivation_method_name}</Typography>
                      <Checkbox
                        checked={cultivationStatusState[status.id]}
                        onChange={(e) => {
                          handleCheckboxChange(status.id, e.target.checked);
                        }}
                      />
                    </div>
                  ))}
                  <br />
                  <Button variant="contained" color="primary" onClick={saveData} style={{ fontSize: '16px', padding: '10px 20px' }}>保存</Button>
                </div>
              ) : (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Field</TableCell>
                        <TableCell>Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(dataState)
                        .filter(key => key !== 'id' && key !== 'field')
                        .map((key) => (
                        <TableRow key={key}>
                          <TableCell style={{ fontSize: '18px' }}>{key}</TableCell>
                          <TableCell style={{ fontSize: '18px' }}>
                            {key === 'location' ? (
                              <div>
                                <div>{dataState.location}</div>
                              </div>
                            ) : key === 'cultivation_status' ? (
                              renderCultivationStatus(dataState[key])
                            ) : key === 'images' ? (
                              // imagesプロパティを表示
                              <div>
                                {/* <Typography>Images:</Typography> */}
                                <ul>
                                {imagePreview ? (
                                  <img
                                    src={imagePreview}
                                    style={{ width: isMobile ? '150px' : '400px', height: isMobile ? 'auto' : 'auto' }}
                                  />
                                ) : dataState.images ? (
                                  <img
                                    src={dataState.images}
                                    style={{ width: isMobile ? '150px' : '400px', height: isMobile ? 'auto' : 'auto' }}
                                  />
                                ) : (
                                  <li>No image available</li>
                                )}
                                </ul>
                              </div>
                            ) : typeof dataState[key] === 'object' ? (
                              <DataDisplay data={dataState[key]} />
                            ) : (
                              dataState[key]
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              <br /> {/* 余白 */}
              <Button variant="contained" color="primary" onClick={() => setEditMode(!editMode)} style={{ fontSize: '16px', padding: '10px 20px' }}>
                {editMode ? 'キャンセル' : 'データ修正'}
              </Button>

            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default DataDisplay;