import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useState, useEffect,useRef } from 'react';
import L from 'leaflet';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import iconMarker from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import iconPurple from '../icon_images/icon_purple.png'; 
import iconYellow from '../icon_images/icon_yellow.png';
import iconGreen from '../icon_images/icon_green.png';
import iconRed from '../icon_images/icon_red.png';
import iconBlue from '../icon_images/icon_blue.png';
import 'leaflet/dist/leaflet.css'; // leaflet.cssのインポート

import { HeatmapLayerFactory } from "@vgrid/react-leaflet-heatmap-layer";

import "@asymmetrik/leaflet-d3";

import MarkerClusterGroup from "react-leaflet-cluster";
import { Icon, divIcon } from "leaflet";



const options = {
	colorScaleExtent: [ 1, undefined ],
	radiusScaleExtent: [ 1, undefined ],
	colorRange: [ '#f7fbff', '#ff0000' ],
	radiusRange: [ 5, 12 ],
};


const HeatmapLayer = HeatmapLayerFactory();


const iconForStatus = (true_statusCount) => {
  if (true_statusCount === 0) {
    return L.icon({
      iconUrl: iconRed,
      iconSize: [36, 36],
      iconAnchor: [18, 36],
      popupAnchor: [0, -18],
    });
  } else if (true_statusCount === 1) {
    return L.icon({
      iconUrl: iconYellow,
      iconSize: [36, 36],
      iconAnchor: [18, 36],
      popupAnchor: [0, -18],
    });
  } else if (true_statusCount === 2) {
    return L.icon({
      iconUrl: iconGreen,
      iconSize: [36, 36],
      iconAnchor: [18, 36],
      popupAnchor: [0, -18],
    });
  } else if (true_statusCount === 3) {
      return L.icon({
        iconUrl: iconBlue,
        iconSize: [36, 36],
        iconAnchor: [18, 36],
        popupAnchor: [0, -18],
      });
  } else {
    // デフォルトのアイコン
    return L.icon({
      iconUrl: iconPurple,
      iconSize: [36, 36],
      iconAnchor: [18, 36],
      popupAnchor: [0, -18],
    });
  }
};


const MapDisplay = ({ locations, cropids, cultivationStatuses,worker,images }) => {

  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const newFilteredPoints = [];
  const [hexLayer, setHexLayer] = useState(null);
  const [showPins, setShowPins] = useState(true);
  const [showHeatmap, setShowHeatmap] = useState(true);
  const [visibleMarkers, setVisibleMarkers] = useState([]);
  const [selectedTab, setSelectedTab] = useState('0');

  useEffect(() => {
    setMap(mapRef.current);
  }, []);

  useEffect(() => {
    const newMarkers = locations.map((location, index) => {
      const coords = location
        .split('(')
        .pop()
        .split(')')[0]
        .split(' ')
        .map(coord => parseFloat(coord));
      const [longitude, latitude] = coords;

      const cultivationNames = cultivationStatuses[index]
        .filter(status => status.status)
        .map(status => status.cultivation_method_name)
        .join(', ');

      const true_statusCount = cultivationStatuses[index].filter(status => status.status).length;
      const statusCount = cultivationStatuses[index].length;
      const markerIcon = iconForStatus(true_statusCount);

      const isElseCondition = selectedTab === 'else' && hasFalseBeforeTrue(cultivationStatuses[index]);
      const not_statusCount = selectedTab === 'else2' && statusCount < maxCultivationNames.length;

      if (selectedTab === 'all' || isElseCondition || not_statusCount || true_statusCount === selectedTab) {
        newFilteredPoints.push([longitude, latitude]);
        return (
          <Marker key={`${index}-${true_statusCount}`} position={[latitude, longitude]} icon={markerIcon}>
            <Popup>
              Crop ID: {cropids[index]}<br />
              Worker: {worker[index]}<br />
              Cultivation Status: {cultivationNames}
              {images[index] && <img src={images[index]} alt="Crop Image" style={{ width: '100%', height: 'auto' }} />}
            </Popup>
          </Marker>
        );
      }
      console.log('image', images[index]);

      return null;
    }).filter(marker => marker !== null);

    setVisibleMarkers(newMarkers);
  }, [locations, cultivationStatuses, worker, images, selectedTab,map]);

  const heatmapPoints = locations.map((location, index) => {
    const [longitude, latitude] = location.split('(').pop().split(')')[0].split(' ').map(coord => parseFloat(coord));
    const true_statusCount = cultivationStatuses[index].filter(status => status.status).length;
    // return [latitude, longitude ]; // 強度を true_statusCount に設定
    return [longitude, latitude];
  });
  


  useEffect(() => {
    if (map && locations.length > 0) {
      let newHexLayer = hexLayer;

      // ヒートマップレイヤーが存在しない場合のみ新しいレイヤーを作成
      if (!hexLayer) {
        newHexLayer = L.hexbinLayer({
          // Hexbinレイヤーのオプションを設定
          colorScaleExtent: [1, undefined],
          radiusScaleExtent: [1, undefined],
          colorRange: ['#f7fbff', '#ff0000'],
          radiusRange: [10, 15],
        }).hoverHandler(L.HexbinHoverHandler.tooltip())
          .addTo(map);

        setHexLayer(newHexLayer);
      }

      // 新しいデータをセット
      newHexLayer.data(newFilteredPoints);
    }
  }, [selectedTab, map, locations, hexLayer]);

  // ヒートマップの表示制御を更新
  useEffect(() => {
    if (showHeatmap && hexLayer) {
      hexLayer.addTo(map);
    } else if (!showHeatmap && hexLayer) {
      hexLayer.remove();
    }
  }, [showHeatmap, hexLayer, map]);
  
 

  const filteredPins=[];

  const getMaxCultivationNames = (cultivationStatuses) => {
    const largestArray = cultivationStatuses.reduce((maxArray, currentArray) =>
      currentArray.length > maxArray.length ? currentArray : maxArray, []);
  
    return largestArray.map(status => status.cultivation_method_name);
  };

  const maxCultivationNames = getMaxCultivationNames(cultivationStatuses);

  console.log(maxCultivationNames.length);

  if (locations.length === 0) {
    return null;
  }

  const createCustomClusterIcon = (cluster) => {

    return new divIcon({
      html: `<div class="cluster-icon">${cluster.getChildCount()}</div>`,
      className: "custom-marker-cluster",
      iconSize: [33, 33]
    });
  };

  const firstLocation = locations[0];
  const coords = firstLocation
    .split('(')
    .pop()
    .split(')')[0]
    .split(' ')
    .map((coord) => parseFloat(coord));

  const [longitude, latitude] = coords;

  const hasFalseBeforeTrue = (statuses) => {
    let foundFalse = false;
    for (const status of statuses) {
      if (!status.status) {
        foundFalse = true;
      } else if (foundFalse) {
        return true;
      }
    }
    return false;
  };

  return (
    <div>
      {/* <div>
        <button onClick={() => setSelectedTab('all')}>全ての房</button>
        <button onClick={() => setSelectedTab(0)}>未着手</button>
        <button onClick={() => setSelectedTab(1)}>房づくり</button>
        <button onClick={() => setSelectedTab(2)}>摘粒</button>
        <button onClick={() => setSelectedTab(3)}>収穫</button>
        <button onClick={() => setSelectedTab('else')}>その他</button>
      </div> */}
      <div>
        <button onClick={() => setShowPins(!showPins)} style={{ width: '120px', height: '50px' ,fontSize: '16px' }}>
          {showPins ? 'ピン非表示' : 'ピン表示'}
        </button>
        <button onClick={() => setShowHeatmap(!showHeatmap)} style={{ width: '170px', height: '50px' ,fontSize: '16px' }}>
          {showHeatmap ? 'ヒートマップ非表示' : 'ヒートマップ表示'}
        </button>
        <br />
        <button onClick={() => setSelectedTab('all') } style={{ width: '100px', height: '50px' ,fontSize: '16px' }}>全ての房</button>
        <button onClick={() => setSelectedTab(0)} style={{ width: '100px', height: '50px' ,fontSize: '16px' }}>未着手</button>
        {maxCultivationNames.map((name, index) => (
          <button key={index+1} onClick={() => setSelectedTab(index+1)} style={{ width: '100px', height: '50px' ,fontSize: '16px' }}>{name}</button>
        ))}
        <button onClick={() => setSelectedTab('else')} style={{ width: '100px', height: '50px' ,fontSize: '16px' }}>記録忘れ</button>
        <button onClick={() => setSelectedTab('else2')} style={{ width: '160px', height: '50px' ,fontSize: '16px' }}>作業項目数不足</button>
      </div>
      <MapContainer ref={setMap} center={[latitude, longitude]} zoom={19} style={{ width: '100%', height: '500px' }}>
        <TileLayer
          minZoom={10}
          maxZoom={25}
          url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {showPins && (
          <MarkerClusterGroup
            chunkedLoading
            maxClusterRadius={200} 
            disableClusteringAtZoom={18} 
            iconCreateFunction={createCustomClusterIcon}>
            {visibleMarkers}
          </MarkerClusterGroup>
        )}
          
      </MapContainer>
    </div>
  );
};

export default MapDisplay;