import React, { useState } from 'react';
import { TextField, Button, Alert, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const DeleteGrapeButton = () => {
  const [cropId, setCropId] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleDeleteAll = () => {
    // 最終確認のダイアログ
    if (window.confirm('本当にすべてのデータを削除しますか？')) {
      fetch(`https://${process.env.REACT_APP_DOMAIN_NAME}/api/v1/grapes/delete_all/`, {
        method: 'DELETE',
      })
        .then(response => {
          if (response.ok) {
            console.log('全削除成功');
            // ここで全削除成功時の処理を行う
          } else {
            console.error('全削除失敗', response);
          }
        })
        .catch(error => console.error('エラー発生:', error));
    }
  };

  const handleDelete = () => {
    if (!cropId) {
      console.error('Crop IDが入力されていません。');
      return;
    }

    fetch(`https://${process.env.REACT_APP_DOMAIN_NAME}/api/v1/grapes/${cropId}/`, {
      method: 'DELETE',
    })
      .then(response => {
        if (response.ok) {
          console.log('削除成功');
          setSuccessMessage('データ削除完了');
          setTimeout(() => {
            setSuccessMessage('');
          }, 5000);
          // ここで削除成功時の処理を行う
        } else {
          console.error('削除失敗', response);
        }
      })
      .catch(error => console.error('エラー発生:', error));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2rem',  // ボタンを下に下げる
      }}
    >
      <TextField
        label="Crop ID"
        value={cropId}
        onChange={e => setCropId(e.target.value)}
        type="number"
        margin="normal"
      />

      <Box
        sx={{
          display: 'flex',
          gap: '1rem',  // ボタン間のスペース
          marginTop: '1rem',  // テキストフィールドとボタンの間のスペース
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleDelete}
        >
          削除
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleDeleteAll}
        >
          すべて削除
        </Button>
      </Box>

      {successMessage && <Alert severity="success">{successMessage}</Alert>}

      <Button
        variant="outlined"
        color="primary"
        fullWidth
        onClick={() => navigate('/')}
        sx={{ marginTop: '1rem' }}
      >
        Back
      </Button>
    </Box>
  );
};

export default DeleteGrapeButton;